import { calculateTax } from '../state/cart'
// We list all actions that should trigger tax recalculation here
const actionsThatShouldRecalculateTax = [
  'cart/addProduct',
  'cart/removeProduct',
  'checkout/updateCheckoutField',
]

const taxApi = storeAPI => next => action => {
  // Call the next middleware (or reducer if this is the last middleware in chain)
  const result = next(action)
  console.log('Checking if tax needs recalculation', action.type, result)
  // If the action type is in our list and it's not a calculateTax action to prevent infinite loop
  if (
    actionsThatShouldRecalculateTax.includes(action.type) &&
    action.type !== 'cart/calculateTax/fulfilled' &&
    action.type !== 'calculateTax.rejected'
  ) {
    // Get the current state after the action is dispatched
    const state = storeAPI.getState()
    console.log('Current state', state)
    const {
      shipping_address,
      // shipping_address2,
      shipping_zip,
      shipping_city,
      shipping_state,
    } = state.checkout
    if (!shipping_address || !shipping_zip || !shipping_city || !shipping_state || shipping_zip.length < 5) {
      console.log('Missing shipping address')
      return result
    }
    console.log('Recalculating tax')
    // Dispatch the calculateTax action with current state of the cart
    storeAPI.dispatch(calculateTax({ cart: state.cart, checkout: state.checkout }))
  }
  // Return the result of calling the next middleware (or reducer if this is the last middleware in chain)
  return result
}

export default taxApi
