// noinspection JSUnusedGlobalSymbols,CommaExpressionJS,ES6ConvertVarToLetConst

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import wrapWithProvider from './redux-provider'

// const initRudderStack = () => {
//   window.rudderanalytics.loadJS()
// }

const initScripts = () => {
  if (window.scriptsDidInit) {
    return false
  }
  window.scriptsDidInit = true // flag to ensure script does not get added to DOM more than once.
 // initRudderStack()
  initKlaviyo()
  initGTM(window, 'dataLayer', 'GTM-K25RWJ')
  if(window.tng) window.tng.runLoad()
}

const initKlaviyo = () => {
// <script type="text/javascript" async="" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=PUBLIC_API_KEY"></script>
  const d = document
  const s = 'script'
  const src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=JHEYDB'
  let f = d.getElementsByTagName(s)[0]
  let j = d.createElement(s)
  j.async = true
  j.src = src
  f.parentNode.insertBefore(j, f)
}

const initScriptsOnEvent = (event) => {
  initScripts()
  event.currentTarget.removeEventListener(event.type, initScriptsOnEvent)
}

const getQueryString = () => {
  const item = window.sessionStorage.getItem('qs')
  if (!item) return {}
  try {
    const qs = JSON.parse(item)
    window.qs = qs
    return qs
  } catch {
    return {}
  }
}

const setQueryString = qs => {
  window.sessionStorage.setItem('qs', JSON.stringify(qs))
  window.qs = qs
}
const addScriptExecutor =  (d,s,src) => {
  let f = d.getElementsByTagName(s)[0]
  let j = d.createElement(s)
  j.async = true
  j.src = src 
  f.parentNode.insertBefore(j, f)
}
const addScript = (src) => {
  addScriptExecutor(document, 'script', src)
}
const initGTM = (w,l,i) => 
{
  w[l] = w[l] || []
  w[l].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  })
  let dl = l != 'dataLayer' ? '&l=' + l : ''
  addScript('https://www.googletagmanager.com/gtm.js?id=' + i + dl)
}

export const onClientEntry = () => {
  let initial_page = window.sessionStorage.getItem('initial_page')
  window._learnq = window._learnq || []
  if (!initial_page) {
    window._initial_page = window.location.href
    window.sessionStorage.setItem('initial_page', window._initial_page)
  } else {
    window._initial_page = initial_page
  }
  !function () {
    // var e = window.rudderanalytics = window.rudderanalytics || []
    // e.methods = ['load', 'page', 'track', 'identify', 'alias', 'group', 'ready', 'reset', 'getAnonymousId', 'setAnonymousId'], e.factory = function (t) {
    //   return function () {
    //     var r = Array.prototype.slice.call(arguments)
    //     return r.unshift(t), e.push(r), e
    //   }
    // }
    // for (var t = 0; t < e.methods.length; t++) {
    //   var r = e.methods[t]
    //   e[r] = e.factory(r)
    // }
    // e.loadJS = function () {
    //   addScript('https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js')
    // },
    // e.load(process.env.GATSBY_RUDDERSTACK_KEY, process.env.GATSBY_RUDDERSTACK_DATA_PLANE_URL)
    const tng = (window.tng = window.tng ||[])
    tng.runLoad = function () { }
    tng.load = function () {
      if(tng.loaded) return
      tng.runLoad = tng.load
      if(!window.scriptsDidInit) return
      tng.loaded = true
      addScript('https://cdn.tangiblee.com/integration/5.0/managed/www.grayandsons.com/revision_1/variation_original/tangiblee-bundle.min.js')
    }
  }()
}

export const onInitialClientRender = () => {
  // console.log("Initial Render")
  document.addEventListener('DOMContentLoaded', () => {
    setTimeout(initScripts, 500)
  })
  document.addEventListener('scroll', initScriptsOnEvent)
  document.addEventListener('mousemove', initScriptsOnEvent)
  document.addEventListener('touchstart', initScriptsOnEvent)
}

// On Route Update (it can accept location and prevLocation as arguments if you need them)
export const onRouteUpdate = () => {
  const vars = getQueryString()
  if (location.search) {
    const query = location.search.substring(1)
    query.split('&').forEach(v => {
      const pair = v.split('=')
      if (pair.length > 1) {
        vars[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
      } else {
        vars[decodeURIComponent(pair[0])] = null
      }
    })
    setQueryString(vars)
  }
  window?._learnq?.push(['track', 'Viewed a Page', { url: location.href }])
  window?._learnq?.push(['track', 'Active on Site', { url: location.href }])
  if (window?.gtag) window?.gtag('event', 'page_view', { page_title: window.document.title, page_location: location.href })
  // window?.rudderanalytics?.page()
}
export const wrapRootElement = wrapWithProvider
